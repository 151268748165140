import React,{useState} from "react";
import "./report.css";
import {ShowMoreHtml} from "../show_more_html/showMoreHtml";

const Report = ({ date, topic, text }) => {

  return (
    text ?
    <div className="report-container">
      <div className="report-date" dangerouslySetInnerHTML={ {__html: date} }></div>
      <div className="py-3 py-md-4 py-lg-5 report-topic">{topic}</div>
        <ShowMoreHtml text={text} className="blnz-article" maxLength={900} moreText="Ver más" lessText="Ver menos" />
    </div>  : null
  );
};

export default Report;
